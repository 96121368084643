import React from "react";

const Completed = () => {
    return (
        <div className="flex flex-col items-center justify-center">
            <svg
                width="107"
                height="74"
                viewBox="0 0 107 74"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M80.2498 35.2212L64.2788 19.2502L69.6248 14.0292L80.2498 24.6542L101.5 3.40417L106.769 8.75017L80.2498 35.2212ZM37.4997 35.4615C32.6873 35.4615 28.5676 33.748 25.1405 30.321C21.7134 26.894 19.9999 22.7742 19.9999 17.9618C19.9999 13.1493 21.7134 9.02954 25.1405 5.60254C28.5676 2.17546 32.6873 0.461914 37.4997 0.461914C42.3122 0.461914 46.4319 2.17546 49.859 5.60254C53.2861 9.02954 54.9996 13.1493 54.9996 17.9618C54.9996 22.7742 53.2861 26.894 49.859 30.321C46.4319 33.748 42.3122 35.4615 37.4997 35.4615ZM0 73.5384V62.4232C0 59.9745 0.665 57.7069 1.995 55.6204C3.32517 53.5339 5.10242 51.9298 7.32675 50.8079C12.2691 48.3848 17.2547 46.5675 22.2835 45.356C27.3123 44.1445 32.3844 43.5388 37.4997 43.5388C42.6151 43.5388 47.6872 44.1445 52.716 45.356C57.7448 46.5675 62.7304 48.3848 67.6727 50.8079C69.8971 51.9298 71.6743 53.5339 73.0044 55.6204C74.3345 57.7069 74.9996 59.9745 74.9996 62.4232V73.5384H0ZM7.49975 66.0385H67.4998V62.4232C67.4998 61.4103 67.2065 60.4728 66.6199 59.6107C66.0334 58.7485 65.2369 58.045 64.2305 57.5C59.9228 55.3783 55.5304 53.7709 51.0533 52.6779C46.5761 51.585 42.0583 51.0385 37.4997 51.0385C32.9412 51.0385 28.4234 51.585 23.9463 52.6779C19.4691 53.7709 15.0767 55.3783 10.769 57.5C9.76258 58.045 8.96613 58.7485 8.37963 59.6107C7.79304 60.4728 7.49975 61.4103 7.49975 62.4232V66.0385ZM37.4997 27.9618C40.2497 27.9618 42.6039 26.9826 44.5622 25.0243C46.5206 23.066 47.4998 20.7118 47.4998 17.9618C47.4998 15.2118 46.5206 12.8576 44.5622 10.8993C42.6039 8.94096 40.2497 7.96179 37.4997 7.96179C34.7497 7.96179 32.3956 8.94096 30.4373 10.8993C28.4789 12.8576 27.4998 15.2118 27.4998 17.9618C27.4998 20.7118 28.4789 23.066 30.4373 25.0243C32.3956 26.9826 34.7497 27.9618 37.4997 27.9618Z"
                    fill="#22C55E"
                />
            </svg>

            <h1 className="text-3xl mt-8 mb-2 font-bold">Password Changed!</h1>
            <p className="font-normal text-base text-slate-500">
                Your password has been successfully changed. You will be redirected to login page.
            </p>
        </div>
    );
};

export default Completed;