import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../button";

import { showErrorToast } from "../Toast";

const CustomProofingSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const CustomProofing = ({
  nextStep,
  setEmail,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  zipCode,
  setZipCode,
  address1,
  setAddress1,
  phone,
  setPhone,
  city,
  setCity,
  state,
  setState,
  country,
  setCountry,
  setshowStepper,
  showOnfidoView,
  identityProofing,
  setIdentityProofing,
  flowValue,
  setFlowValue,
  getUserIDFromEmail,
  getUserIDByEmailFromAuth0,
}) => {
  const [selectedIdp, setSelectedIdp] = useState("");
  useEffect(() => {
    const idp = localStorage.getItem("selectedIdp");
    if (idp) {
      setSelectedIdp(idp);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      email: "",
      zipCode: zipCode,
      address1: address1,
      phone: phone,
      city: city,
      state: state,
      country: country,
      acceptTerms: false,
    },
    validationSchema: CustomProofingSchema,
    onSubmit: async (values) => {
      setFirstName(values.firstName);
      setLastName(values.lastName);
      setEmail(values.email);
      setZipCode(values.zipCode);
      setAddress1(values.address1);
      setPhone(values.phone);
      setCity(values.city);
      setState(values.state);
      setCountry(values.country);

      if (selectedIdp === "Okta") {
        try {
          await getUserIDFromEmail(values.email);
          // Proceed to the next step if needed
          nextStep();
        } catch (error) {
          showErrorToast("User doesn't  exist");
          console.error("Error fetching user ID:", error);
        }
      } else {
        try {
          await getUserIDByEmailFromAuth0(values.email);
          nextStep();
        } catch (error) {
          showErrorToast("User doesn't  exist");
          console.error("Error fetching user ID:", error);
        }
      }
    },
  });

  const [next, setNext] = useState(false);

  const enterGoodData = () => {
    console.log("Enter good data");
    populateInputs(
      "John",
      "Pprivet",
      "647 NORRISTOWN RD",
      "HAMPTON",
      "VA",
      "23666",
      "USA",
      "5137111111"
    );
    // Clear any existing form errors
    // formik.resetForm({ values: formik.initialValues });
  };

  function populateInputs(
    fName,
    lName,
    address,
    city,
    state,
    zip,
    country,
    phone
  ) {
    // Set Formik field values
    formik.setFieldValue("firstName", fName);
    formik.setFieldValue("lastName", lName);
    formik.setFieldValue("zipCode", zip);
    formik.setFieldValue("address1", address);
    formik.setFieldValue("phone", phone);
    formik.setFieldValue("city", city);
    formik.setFieldValue("state", state);
    formik.setFieldValue("country", country);
  }

  return (
    <div className="flex flex-col gap-6 border shadow-sm dark:bg-dark bg-white max-w-[550px] box-content lg:mx-auto md:mx-4 sm:mx-2 my-10 rounded-lg p-8">
      <div className="flex flex-col">
        <div className="py-2">
          <h4 className="text-blue-950 text-2xl font-medium mb-1">
            Identity Proofing Method
          </h4>
          <p className="text-neutral-500 text-sm mb-2">
            Please select the identity proofing method to proceed.
          </p>
        </div>
        <div className="flex flex-col gap-3 mb-4">
          {
            <button
              id="submit"
              type="button"
              className={`w-full border-2 items-center py-4 px-4 justify-start flex rounded-lg focus:outline-none focus:ring focus:ring-blue-300 hover:border-blue-800 hover:text-blue-800 hover:bg-blue-50 font-medium ${
                identityProofing === "Proceed With  Document Verification"
                  ? "text-blue-900 border-blue-900 bg-blue-50"
                  : ""
              } ${identityProofing}`}
              onClick={() => {
                setIdentityProofing("Proceed With  Document Verification");
                setshowStepper(!showOnfidoView);
                setNext(true);
                setFlowValue("Document Verification");
                formik.resetForm();
              }}
            >
              Document Verification{" "}
              {identityProofing === "Proceed With  Document Verification" && (
                <span className="ml-auto text-blue-900">
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 8L5.7257 12.2257C6.1415 12.6415 6.82457 12.6105 7.20102 12.1588L16.5 1"
                      stroke="#1e3a8a"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                </span>
              )}
            </button>
          }
          <button
            type="button"
            className={`w-full  border-2 font-medium items-center py-4 px-4 justify-start flex rounded-lg focus:outline-none focus:ring focus:ring-blue-300 hover:border-blue-800 hover:text-blue-800 hover:bg-blue-50 ${
              identityProofing === "Proceed With KBA"
                ? "text-blue-900 border-blue-900 bg-blue-50"
                : ""
            }`}
            onClick={() => {
              setIdentityProofing("Proceed With KBA");
              setshowStepper(!showOnfidoView);
              setNext(true);
              setFlowValue("KBA");
              formik.resetForm();
            }}
          >
            KBA
            {identityProofing === "Proceed With KBA" && (
              <span className="ml-auto text-blue-900">
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 8L5.7257 12.2257C6.1415 12.6415 6.82457 12.6105 7.20102 12.1588L16.5 1"
                    stroke="#1e3a8a"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
            )}
          </button>
        </div>
      </div>
      <div className="py-2">
        <h4 className="text-blue-950 text-2xl font-medium mb-1">
          Enter Email Address
        </h4>
        <p className="text-neutral-500 text-sm mb-2">
          Please enter your email to recover your account.
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="shadow-sm border p-2 w-full"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
        )}

        {flowValue === "KBA" && (
          <>
            <button
              className="py-3 px-4 border-2 border-gray-200 hover:bg-gray-200 text-gray-700 text-md  w-full rounded"
              type="button"
              id="goodData"
              onClick={enterGoodData}
            >
              Fill <b>Accurate</b> Information
            </button>

            <input
              type="text"
              placeholder="Enter your First Name"
              id="fname"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow-sm border p-2 w-full mt-4" // Adjust styling as needed
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.firstName}
              </div>
            )}

            <input
              type="text"
              placeholder="Enter your Last Name"
              id="lname"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow-sm border p-2 w-full mt-4" // Adjust styling as needed
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.lastName}
              </div>
            )}

            <input
              type="text"
              placeholder="Enter your zip code"
              id="zipCode"
              name="zipCode"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow-sm border p-2 w-full mt-4" // Adjust styling as needed
            />
            {formik.touched.zipCode && formik.errors.zipCode && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.zipCode}
              </div>
            )}

            <input
              type="text"
              placeholder="Enter your address1"
              id="addr1"
              name="address1"
              value={formik.values.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow-sm border p-2 w-full mt-4" // Adjust styling as needed
            />
            {formik.touched.address1 && formik.errors.address1 && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.address1}
              </div>
            )}

            <input
              type="text"
              placeholder="Enter your Phone"
              id="phn"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow-sm border p-2 w-full mt-4" // Adjust styling as needed
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.phone}
              </div>
            )}

            <input
              type="text"
              placeholder="Enter your City"
              id="ct"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow-sm border p-2 w-full mt-4" // Adjust styling as needed
            />
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.city}
              </div>
            )}

            <input
              type="text"
              placeholder="Enter your State"
              id="ct"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow-sm border p-2 w-full mt-4" // Adjust styling as needed
            />
            {formik.touched.state && formik.errors.state && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.state}
              </div>
            )}

            <input
              type="text"
              placeholder="Enter your Country"
              id="ctry"
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow-sm border p-2 w-full mt-4" // Adjust styling as needed
            />
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.country}
              </div>
            )}
          </>
        )}

        <div className="flex flex-col gap-4 pt-8">
          {!!identityProofing && (
            <Button variant="primary" type="button" label={identityProofing} />
          )}
        </div>
      </form>
    </div>
  );
};

export default CustomProofing;
