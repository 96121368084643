import React, { useState } from "react";
import Accuant from "../Acuant";
import License from "../../images/License.png";
import Identity from "../../images/Identity.png";
import Residency from "../../images/Residency.png";
import Passport from "../../images/Passport.png";
import Arrow from "../../images/arrow.svg";

export const IdentifyButton = ({
  handleClick,
  value,
  imageSrc,
  title,
  subTitle,
  disabled,
}) => {
  return (
    <button
      className={`flex-row px-3 py-3 flex items-center gap-4 border border-blue-200 text-left rounded-lg  ${
        disabled
          ? "disabled:text-blue-800 cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-100"
          : "hover:bg-blue-50"
      }`}
      value={value}
      disabled={disabled}
      aria-disabled="true"
      onClick={handleClick}
    >
      <img src={imageSrc} width="40" />
      <div className="flex-1">
        <div className="font-bold text-blue-900">{title}</div>
        <div className="text-gray-500 text-sm font-medium">{subTitle}</div>
      </div>
      {!disabled && (
        <div>
          <img src={Arrow} width="16" style={{ opacity: "0.5" }} />
        </div>
      )}
    </button>
  );
};

const SelectDocument = ({
  nextStep,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  username,
  setUsername,
  email,
  setEmail,
  password,
  setPassword,
  vRequired,
  setValueRequired,
  sdkToken,
  setSDKToken,
  loading,
  setLoading,
  isUserCreated,
  setIsUserCreated,
  verifyState,
  setVerifyState,
  base64BackImageData,
  setBase64BackImageData,
  base64FrontImageData,
  setBase64FrontImageData,
  setBase64SelfieImageData,
}) => {
  const [showAccuant, setShowAccuant] = useState(false);

  const handleAccuantClick = () => {
    setShowAccuant(true);
  };

  return (
    <div className="form-main-body">
      {!showAccuant && (
        <div>
          <h1 className="verify-identity-title text-blue-950 text-2xl font-medium mb-1 mt-4">
            Verify Your Identity
          </h1>
          <p className="font-normal text-sm text-slate-500 mb-8">
            Please select the document type you want to use for verification.
          </p>
          <div
            className="flex flex-col gap-4 "
            placeholder="Select a document type"
          >
            <IdentifyButton
              imageSrc={License}
              title={"Driving License"}
              subTitle={"Front and Back"}
              value={"Driving_License"}
              handleClick={""}
              disabled={"true"}
            />

            <IdentifyButton
              imageSrc={Identity}
              title={"Identity Card"}
              subTitle={"Photo Page"}
              value={"Identity Card"}
              handleClick={handleAccuantClick}
            />

            <IdentifyButton
              imageSrc={Passport}
              title={"Passport"}
              subTitle={"Front and Back"}
              value={"passport"}
              handleClick={""}
              disabled={"true"}
            />

            <IdentifyButton
              imageSrc={Residency}
              title={"Residence Permit"}
              subTitle={"Front and Back"}
              value={"residencePermit"}
              handleClick={""}
              disabled={"true"}
            />
          </div>
        </div>
      )}
      {showAccuant && (
        <Accuant
          nextStep={nextStep}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          username={username}
          setUsername={setUsername}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          vRequired={vRequired}
          setValueRequired={setValueRequired}
          sdkToken={sdkToken}
          setSDKToken={setSDKToken}
          loading={loading}
          setLoading={setLoading}
          isUserCreated={isUserCreated}
          setIsUserCreated={setIsUserCreated}
          verifyState={verifyState}
          setVerifyState={setVerifyState}
          base64BackImageData={base64BackImageData}
          setBase64BackImageData={setBase64BackImageData}
          base64FrontImageData={base64FrontImageData}
          setBase64FrontImageData={setBase64FrontImageData}
          setBase64SelfieImageData={setBase64SelfieImageData}
        />
      )}
    </div>
  );
};

export default SelectDocument;
