import React, { useState, useRef, useEffect } from "react";
import LottieAnimation from "../../components/LottieAnimation";
import { showErrorToast, showSuccessToast } from "../Toast";
import { useNavigate, useLocation } from "react-router-dom";
import ApiService from "../../services/ApiService";
import Button from "../button";

const UploadFaceImages = ({
    nextStep,
    firstName,
    lastName,
    email,
    password,
    setIsUserCreated,
    base64BackImageData,
    base64FrontImageData,
    base64SelfieImageData,
    setBase64SelfieImageData,
    setCurrent,
}) => {
    let face1;
    const [image, setImage] = useState(null);
    const [isAnimationVisible, setAnimationVisible] = useState(false);
    const [selectedIdp, setSelectedIdp] = useState("");

    const inputRef = useRef();
    const apiService = new ApiService(process.env.REACT_APP_BASEURL);

    const handleTriggerClick = () => {
        inputRef.current.click();
    };



    const handleImageChange = (e) => {
        const file = e.target.files[0];

        console.log(file);
        const reader = new FileReader();

        reader.onloadend = () => {
            setImage(reader.result);
            // Use the logic from facesSelected and loadImage here
            loadImage(file);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    function loadImage(imageFile) {
        var reader = new FileReader();
        reader.onload = function (e) {
            face1 = e.target.result.replace("data:image/jpeg;base64,", "");
            setBase64SelfieImageData(face1);
        };
        reader.readAsDataURL(imageFile);
    }

    const navigate = useNavigate();

    const submitRegistration = () => {
        setCurrent(3); // Advance to the last step
    };






    const onSubmit = async (data) => {
        let postValue = {
            frontData: base64FrontImageData,
            backData: base64BackImageData,
            selfie: base64SelfieImageData,
        };

        try {
            setAnimationVisible(true);
            showSuccessToast("The user is being verified. Please wait for some time");

            const resp = await apiService.post(
                process.env.REACT_APP_ONFIDO_CREATE_USER,
                postValue
            );

            if (selectedIdp === "Okta") {
                // await createOktaUser();
                nextStep();

            } else {
                // await createAuth0User();
                nextStep();
            }

            setAnimationVisible(false);
            nextStep();

        } catch (error) {
            console.log("Error is:", error);

            showErrorToast("Failed to create user. Please try again later.");

            setAnimationVisible(false);
        }
    };

    return (
        <div>
            {isAnimationVisible && <LottieAnimation />}
            <h1 className="face-recognition-title text-blue-950 text-2xl font-medium mb-1 mt-4">
                Upload your face image{" "}
            </h1>
            <p className="text-neutral-500 text-sm mb-2">
                Please follow the notes below.
            </p>
            <ul className="my-8">
                <li>Face forward and make sure your entire face is visible.</li>
                <li>Remove your glasses, if necessary.</li>
            </ul>

            {!image && (
                <div className="text-center">
                    <Button
                        type="secondary"
                        label="Click Here to Upload"
                        handleClick={handleTriggerClick}
                    />
                </div>
            )}

            <input
                type="file"
                ref={inputRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
            />

            {image && (
                <div className="text-center mt-12">
                    <img
                        className="rounded selfie-thumbnail border-secondary text-center mx-auto mb-4"
                        width={150}
                        height={150}
                        src={image}
                        alt="Uploaded Face Image"
                    />
                    <p className="mb-0"> Make sure your entire face is visible</p>
                    <button
                        className="text-blue-950 hover:text-blue:500 mt-2 tex-sm border-b-2 border-blue-950 text-sm font-medium"
                        onClick={() => {
                            setImage(null);
                            inputRef.current.value = "";
                        }}
                    >
                        Click Here to Edit/Change Image
                    </button>
                </div>
            )}

            {image && (
                <Button
                    className="w-full mt-8"
                    handleClick={onSubmit}
                    label="Upload"
                />
            )}
        </div>
    );
};

export default UploadFaceImages;
