import React, { useState } from "react";
import { Steps } from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  FileDoneOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NavbarCom from "../NavbarCom";
import Footer from "../Footer";

import TransactionDetails from "./TransactionDetails";


import ThreatSelectDocument from "./ThreatSelectDocument";

import ThreatUploadFaceImage from "./ThreatUploadFaceImage";
import ThreatComplete from "./ThreatComplete";
const people = [
  { id: 1, name: "Tom Cook-626217817271" },
  { id: 2, name: "Wade Cooper-8191728199101" },
  { id: 3, name: "Tanya Fox-019163728171" },
];
const ThreatStepper = () => {
  const [current, setCurrent] = useState(0);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [vRequired, setValueRequired] = useState(true);
  const [sdkToken, setSDKToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [verifyState, setVerifyState] = useState({
    isComplete: undefined,
    checkId: "",
  });

  const [base64FrontImageData, setBase64FrontImageData] = useState(null);
  const [base64BackImageData, setBase64BackImageData] = useState(null);
  const [base64SelfieImageData, setBase64SelfieImageData] = useState(null);
  const [selected, setSelected] = useState(people[1]);

  const balance = 856789;
  const formattedBalance = new Intl.NumberFormat().format(balance);

  const nextStep = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = parseFloat(value.replace(/[$,]/g, ""));

    if (isNaN(numericValue) || numericValue <= 0) {
      setError("Please enter a valid amount greater than 0");
    } else if (numericValue > balance) {
      setError("Amount exceeds available balance");
    } else {
      setError("");
    }

    setAmount(value);
  };;
  const items = [
    {
      key: "Transaction Details",
      title: "Transaction Details",
      icon: <AccountBalanceWalletIcon />,
    },
    {
      key: "LexisNexis Document Verification",
      title: "LexisNexis Document Verification",
      icon: <SolutionOutlined />,
    },
    {
      key: "Facial Recognition",
      title: "Facial Recognition",
      icon: <SmileOutlined />,
    },
    { key: "Completed", title: "Completed", icon: <FileDoneOutlined /> },
  ];

  return (
    <>
      <NavbarCom />
      <div className="p-10 py-24 bg-neutral-100">
        <div className="multi-step-parent-wrapper max-w-[650px] bg-white mx-auto  p-8 shadow-lg rounded-lg ">
          {current > 0 && (
            <button
              className="text-blue-950 hover:bg-neutral-200 mt-2 tex-sm  border-blue-950 text-sm font-medium rounded-full p-2  bg-neutral-100"
              style={{ margin: "0 8px" }}
              onClick={prev}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            </button>
          )}

          <Steps
            size="small"
            className="bg-white rounded-top pt-4 pb-2"
            current={current}
            items={items}
          />

          <div className="">
            {current === 0 && (
              <TransactionDetails
                amount={amount}
                setAmount={setAmount}
                handleAmountChange={handleAmountChange}
                balance={formattedBalance}
                error={error}
                setError={setError}
                nextStep={nextStep}
                selected={selected}
                setSelected={setSelected}
                people={people}
              />
            )}
            {current === 1 && (
              <ThreatSelectDocument
                nextStep={nextStep}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                base64BackImageData={base64BackImageData}
                setBase64BackImageData={setBase64BackImageData}
                base64FrontImageData={base64FrontImageData}
                setBase64FrontImageData={setBase64FrontImageData}
                base64SelfieImageData={base64SelfieImageData}
                setBase64SelfieImageData={setBase64SelfieImageData}
              />
            )}
            {current === 2 && (
              <ThreatUploadFaceImage
                nextStep={nextStep}
                setCurrent={setCurrent}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                base64BackImageData={base64BackImageData}
                setBase64BackImageData={setBase64BackImageData}
                base64FrontImageData={base64FrontImageData}
                setBase64FrontImageData={setBase64FrontImageData}
                base64SelfieImageData={base64SelfieImageData}
                setBase64SelfieImageData={setBase64SelfieImageData}
              />
            )}
            {current === 3 && (
              <ThreatComplete amount={amount} selected={selected} />
            )}
          </div>
          <div className="flex justify-between w-full"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ThreatStepper;
