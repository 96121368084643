import React from "react";
import Lottie from "lottie-react";
import Animation from "../lotties/Animation.json";

const LottieAnimation = () => (
    <div
        style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw", // Full width
            height: "100vh", // Full height
            background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Adjust the z-index as needed to ensure it overlays other elements
        }}
    >
        <Lottie animationData={Animation} loop={true} />
    </div>
);

export default LottieAnimation;