import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Link } from "react-router-dom";

const ThreatComplete = ({ amount, selected }) => {
  // Check if selected is defined before accessing its properties
  const formattedBalance = new Intl.NumberFormat().format(amount);
  const recipientName = selected ? selected.name : "";

  // Get the current date in the format "dd Month yyyy"
  const currentDate = new Date().toLocaleDateString("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  return (
    <div className="text-center w-full">
      <div className="flex-col justify-center w-full items-start gap-4 inline-flex">
        <div className="justify-center items-center gap-4 inline-flex">
          <div className="w-6 h-6 relative text-green-500">
            <CheckCircleOutlinedIcon />
          </div>
          <div className="text-neutral-700 text-xl">
            You have successfully completed the transfer.
          </div>
        </div>
        <div className="w-full h-px border border-zinc-200"></div>
        <div className="border border-zinc-200"></div>
        <div className="justify-between items-center inline-flex w-full">
          <div className="text-zinc-500 text-base">From </div>
          <div className="text-neutral-600 text-base text-left">
            A/C No: 766666211612
          </div>
        </div>
        <div className="justify-between items-center inline-flex w-full">
          <div className="text-zinc-500 text-base">To </div>
          <div className="text-neutral-600 text-base">{recipientName}</div>
        </div>
        <div className="justify-between items-center inline-flex w-full">
          <div className="text-zinc-500 text-base">Amount</div>
          <div className="text-neutral-600 text-base">$ {formattedBalance}</div>
        </div>
        <div className="justify-between items-center inline-flex w-full">
          <div className="text-zinc-500 text-base">Date </div>
          <div className="text-neutral-600 text-base">{currentDate}</div>
        </div>
      </div>
      <Link
        to="/"
        className="px-6 py-4 bg-slate-900 rounded justify-center items-center gap-4 inline-flex hover:bg-blue-900 transition-colors duration-200"
      >
        <p className="text-white flex gap-2">

          Go to Home Page
        </p>
      </Link>
    </div>
  );
};

export default ThreatComplete;
