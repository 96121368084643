import React from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import Headerbar from './Headerbar'

const SuccessfulRegister = () => {
    return (
        <div>
            <Headerbar />
            <div className='success-form-parent d-flex justify-content-center  align-items-center '>
                <div className='success-form p-4 justify-content-center  align-items-center rounded-3  bg-white flex flex-column '>
                    <h2 className='text-center'>Successfully Registered</h2>
                    <i className="fas fa-3x text-success fa-user-check mr-2"></i>

                    <div className='d-flex flex-column  align-items-center g-2 '>
                        <p className='register-success-msg text-center'>Your Account has been successfully created.</p>

                    </div>
                    {/* <div className='d-flex '>
                        <p className='mb-0 text-center'>Please click here to</p>
                        <Link to='/login' className='ml-1 fw-medium' >
                            Sign in
                        </Link>
                    </div> */}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SuccessfulRegister