import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { showErrorToast } from "./Toast";
import Button from "./button";
// import { AcuantCamera, AcuantCameraUI } from '../webSdk/AcuantCamera.js';
// import { AcuantJavascriptWebSdk } from '../webSdk/AcuantJavascriptWebSdk.min.js';
// import { AcuantPassiveLiveness } from '../../public/lib/webSdk';

const Acuant = ({
  nextStep,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  username,
  setUsername,
  email,
  setEmail,
  password,
  setPassword,
  vRequired,
  setValueRequired,
  sdkToken,
  setSDKToken,
  loading,
  setLoading,
  isUserCreated,
  setIsUserCreated,
  verifyState,
  setVerifyState,
  base64BackImageData,
  setBase64BackImageData,
  base64FrontImageData,
  setBase64FrontImageData,
  base64SelfieImageData,
  setBase64SelfieImageData,
}) => {
  const [documentType, setDocumentType] = useState(null);
  const [frontImage, setFrontImage] = useState({
    imageName: "",
    imageURL: "",
  });
  const [backImage, setBackImage] = useState({
    imageName: "",
    imageURL: "",
  });
  const AcuantJavascriptWebSdk = window.AcuantJavascriptWebSdk;
  const AcuantCamera = window.AcuantCamera;
  const AcuantCameraUI = window.AcuantCameraUI;

  // const [base64FrontImageDataToDisplay, setbase64FrontImageDataToDisplay] =useState(null);

  const [isLoading, setIsloading] = useState(false);

  const navigate = useNavigate();

  const cameraBtn = document.getElementById("camera-btn");
  const camera = document.getElementById("acuant-camera");

  var currentResult = {};

  const faceCaptureContainer = document.getElementById(
    "acuant-face-capture-container"
  );

  var liveCaptureFailed = false;

  //Good practice to end workers when you are done with them (does not have to be in onbeforeunload, end them when you are done with them in your workflow and won't use them again).
  window.onbeforeunload = function (event) {
    end();
  };

  function showCameraButton() {
    cameraBtn.style.display = "block";
  }

  function closeCamera() {
    // camera.style.display = 'none';
    // result.style.display = 'block';
    // cameraBtn.style.display = 'block';
    // desc.style.display = 'block';
  }

  useEffect(() => {
    if (documentType) startCamera();
  }, [documentType]);
  function openCamera(topDocumentType) {
    // desc.style.display = 'none';
    // result.style.display = 'none';
    // faceButtons.style.display = 'none';
    // resultFace.style.display = 'none';
    setDocumentType(topDocumentType);
  }

  const options = {
    text: {
      NONE: "ALIGN",
      SMALL_DOCUMENT: "MOVE CLOSER",
      GOOD_DOCUMENT: null, //null countdown
      BIG_DOCUMENT: "TOO CLOSE",
      CAPTURING: "CAPTURING",
      TAP_TO_CAPTURE: "TAP TO CAPTURE",
    },
  };

  var cameraCallback = {
    onCaptured,
    onCropped,
    onFrameAvailable: (response) => {},
    onError,
  };

  function startCamera() {
    window.init();
    currentResult = {};
    if (AcuantCamera.isCameraSupported && !liveCaptureFailed) {
      cameraBtn.style.display = "none";
      camera.style.display = "block";
      AcuantCameraUI.start(cameraCallback, options);
    } else {
      startManualCapture();
    }
  }

  function onError(error, code) {
    camera.style.display = "none";
    faceCaptureContainer.style.display = "none";
    console.error(error, code);
    liveCaptureFailed = true;

    if (code === AcuantJavascriptWebSdk.REPEAT_FAIL_CODE) {
      //live capture was started after previous failure leading to manual capture, show any elements you may have hidden as the user can cancel the prompt and could otherwise end up on a blank page.
      cameraBtn.style.display = "block";
    } else {
      modal.style.display = "block";
      switch (code) {
        case AcuantJavascriptWebSdk.SEQUENCE_BREAK_CODE: //camera froze/crashed; usually happens due to an ios 15 bug
          modalText.innerHTML = "Live Camera failed. Start manual capture.";
          break;
        case AcuantJavascriptWebSdk.START_FAIL_CODE: //camera not supported or permission not granted
          modalText.innerHTML =
            "Live Camera failed to open. Start manual capture.";
          break;
        case AcuantJavascriptWebSdk.HEIC_NOT_SUPPORTED_CODE:
          modalText.innerHTML = "HEIC image failed to process.";
          break;
        default: //unknown error (shouldn't happen)
          modalText.innerHTML = "Unknown camera failure. Start manual capture.";
          break;
      }
    }
  }

  function onCaptured(response) {
    // cameraBtn.style.display = 'none';
    // modal.style.display = 'none';
    // loader.style.display = 'block';
    console.log("onCaptured response", response);
  }

  function onCropped(response) {
    // console.log('documentType in onCropped', documentType);
    setDocumentType(null);
    if (!response) {
      alert("Wrong image!!");
    }
    console.log("onCropped response", response);
    console.log("documentType on onCropped:", documentType);
    if (documentType === "back") {
      console.log("back");
      const baseBack64ImageDataResponse = response.image.data;
      const [, base64BackImageData] = baseBack64ImageDataResponse.split(",");
      // props.onCroppedCallback(base64BackImageData, documentType);
      return;
    } else {
      console.log("front");
      const baseFront64ImageDataResponse = response.image.data;
      const [, base64FrontImageData] = baseFront64ImageDataResponse.split(",");
      // setbase64FrontImageDataToDisplay(base64FrontImageData);
      // props.onCroppedCallback(base64FrontImageData, documentType);
      setBase64FrontImageData(base64FrontImageData);
      return;
    }
  }

  function startManualCapture() {
    // debugger;
    AcuantCamera.startManualCapture(cameraCallback);
  }

  function end() {
    AcuantJavascriptWebSdk.end();
  }

  //**************** Face Match Example ==========================

  let face1;
  let face3;
  let face4;

  function facesSelected(e) {
    const inputs = document.getElementById("face-button-1").files[0];
    loadImage(inputs);
    setFrontImage({
      imageName: inputs.name,
      imageURL: URL.createObjectURL(e.target.files[0]),
    });
  }

  function loadImage(imageFile) {
    var reader = new FileReader();
    reader.onload = function (e) {
      face1 = e.target.result.replace("data:image/jpeg;base64,", "");

      setBase64FrontImageData(face1);
    };
    reader.readAsDataURL(imageFile);
  }

  function facesSelected1(e) {
    const inputs = document.getElementById("back-button-1").files[0];
    loadImage1(inputs);
    setBackImage({
      imageName: inputs.name,
      imageURL: URL.createObjectURL(e.target.files[0]),
    });
  }

  function loadImage1(imageFile) {
    var reader = new FileReader();
    reader.onload = function (e) {
      face3 = e.target.result.replace("data:image/jpeg;base64,", "");

      setBase64BackImageData(face3);
    };
    reader.readAsDataURL(imageFile);
  }

  //**************** Custom Modal===========================
  // Get the modal
  const modal = document.getElementById("acuant-modal");
  const modalText = document.getElementById("acuant-modal-text");

  document.addEventListener("DOMContentLoaded", function () {
    var span = document.getElementsByClassName("close")[0];
    if (span) {
      span.onclick = function () {
        closeCamera();
        modal.style.display = "none";
      };
    } else {
      console.error('No element with className "close" found');
    }
  });

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target == modal) {
      closeCamera();
      modal.style.display = "none";
    }
  };
  //**************** Custom Modal===========================

  const acuantConfig = {
    path: "/webSdk/",
    jpegQuality: 1.0, //strongly advised not to modify (can be left out will default to 1.0)
  };

  // const clickHandler = () => {
  //   console.log(
  //     'base64FrontImageDataToDisplay:',
  //     base64FrontImageDataToDisplay
  //   );
  //   var copyText = document.getElementById('idText');
  //   // Select the text field
  //   copyText.select();
  // };

  function handleNextStep() {
    // Validate that both front and back images are chosen before proceeding
    if (
      frontImage.imageName &&
      backImage.imageName &&
      frontImage.imageName != backImage.imageName
    ) {
      // Perform additional validation or actions if needed
      // Call the nextStep function if all conditions are met
      nextStep();
    } else {
      // Display an error message or handle the case where images are not chosen
      showErrorToast("Please choose different images for both front and back.");
    }
  }

  return (
    <>
      <div className="doc-upload-parent">
        <div className="document-upload-form">
          <div>
            <h4 className="upload-document-title text-blue-950 text-2xl font-medium mb-1 mt-8">
              Upload Document
            </h4>
            <p className="text-neutral-500 text-sm mb-2">
              Please upload the document you want to use for verification.
            </p>
          </div>
          <div
            id="acuant-loader"
            className="row text-center"
            style={{ display: "none" }}
          >
            <div className="col-md-12">
              <div className="loader"></div>
            </div>
          </div>
          <div id="camera-btn" className="w-full">
            <div className="document-upload-section my-4">
              <div
                id="face-buttons"
                className={
                  "w-full " +
                  (frontImage.imageName
                    ? "bg-gray-100 p-4 rounded border border-gray-200"
                    : "")
                }
              >
                {frontImage.imageName && (
                  <div className="text-center">
                    <div className="text-blue-950 mb-3 text-center font-medium">
                      Uploaded Front Document
                    </div>
                    <img
                      className="inline-block rounded-lg max-w-52 max-h-52 h-full w-full object-cover"
                      src={frontImage.imageURL}
                    />
                    <div className="mt-2 mb-5 text-center">
                      <b className="text-gray-600">{frontImage.imageName}</b>
                    </div>
                  </div>
                )}
                <label
                  className="transition-colors duration-200 text-sm  text-neutral-500 rounded flex py-2 px-2 md:py-3 border-2 focus:outline-none bg-white hover:bg-blue-900 hover:border-blue-900 focus:ring focus:ring-blue-800 hover:text-white justify-center text-md w-full"
                  for="face-button-1"
                >
                  {frontImage.imageName
                    ? "Change Front Image"
                    : "Choose Front Image"}
                </label>
                <input
                  className="hidden"
                  id="face-button-1"
                  type="file"
                  multiple={false}
                  accept="image/jpeg"
                  onChange={facesSelected}
                />
              </div>
              <div
                id="face-buttons"
                className={
                  "w-full mt-4 " +
                  (backImage.imageName
                    ? "bg-gray-100 p-4 rounded border border-gray-200"
                    : "")
                }
              >
                {backImage.imageName && (
                  <div className="text-center">
                    <div className="text-gray-500 mb-3 text-center font-medium">
                      Uploaded Back Document
                    </div>
                    <img
                      className="inline-block rounded-lg max-w-52 max-h-52 h-full w-full object-cover"
                      src={backImage.imageURL}
                    />
                    <div className="mt-2 mb-5 text-center text-sm">
                      <b className="text-blue-950">{backImage.imageName}</b>
                    </div>
                  </div>
                )}
                <label
                  className="transition-colors duration-200 text-sm  text-neutral-500 rounded flex py-2 px-2 md:py-3 border-2 focus:outline-none bg-white hover:bg-blue-900 hover:border-blue-900 focus:ring focus:ring-blue-800 hover:text-white justify-center text-md w-full"
                  htmlFor="back-button-1"
                >
                  {backImage.imageName
                    ? "Change Back Image"
                    : "Choose Back Image"}
                </label>

                <input
                  id="back-button-1"
                  type="file"
                  className="hidden"
                  accept="image/jpeg"
                  multiple={false}
                  onChange={facesSelected1}
                />
              </div>
              <div className="text-center">
                <span className="my-4 bg-slate-200  font-medium rounded-full px-3 py-1 inline-block text-xs">
                  Allowed Formats: <b>JPG, PNG, JPEG.</b>
                </span>
              </div>
            </div>
          </div>

          {/* {base64FrontImageDataToDisplay && (
        <textarea id='idText'>{base64FrontImageDataToDisplay}</textarea>
      )} */}

          {/* <div
            id="acuant-face-capture-container"
            className="face-camera-container"
          >
            <div id="face-detection-text" className="detection-state"></div>
          </div> */}
          {/* <div id="acuant-camera"></div> */}
          <div id="result-id" style={{ display: "none" }}>
            <div className="row text-center">
              <div className="col-md-12">
                <p id="result-error">
                  An error has occurred while cropping the image. Please try
                  again.
                </p>
                <img
                  id="result-id-img"
                  className="fit-screen"
                  style={{ display: "block" }}
                ></img>
              </div>
            </div>
          </div>
          <div className="!text-neutral-500 upload-document-buttons flex flex-col gap-4 mt-6">
            <Button
              isDisabled="true"
              type="secondary"
              label="Continue on phone"
              className="!text-neutral-500 "
            />
            <Button label="Next" handleClick={handleNextStep} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Acuant;
