import React, { useState } from "react";
import { Steps } from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  FileDoneOutlined,
  SmileOutlined,
} from "@ant-design/icons";

import PasswordIcon from "@mui/icons-material/Password";

import ValidateWithLN from "../kba/ValidateWithLN";
import NavbarCom from "../NavbarCom";
import Footer from "../Footer";
import CustomProofing from "./CustomProofing ";
import SelectDocuments from "./SelectDocuments";
import CreatePassword from "./CreatePassword";
import UploadFaceImages from "./UploadFaceImages";
import Completed from "./Completed";
import ApiService from "../../services/ApiService";

const ForgotStepper = () => {
  const [current, setCurrent] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [vRequired, setValueRequired] = useState(true);
  const [sdkToken, setSDKToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [verifyState, setVerifyState] = useState({
    isComplete: undefined,
    checkId: "",
  });
  const [showStepper, setshowStepper] = useState(false);
  const [base64FrontImageData, setBase64FrontImageData] = useState(null);
  const [base64BackImageData, setBase64BackImageData] = useState(null);
  const [base64SelfieImageData, setBase64SelfieImageData] = useState(null);

  const [showOnfidoView, setShowOnfidoView] = useState(false);
  const [identityProofing, setIdentityProofing] = useState("");
  const [flowValue, setFlowValue] = useState("");
  const forgotStepperValue = true;
  const apiService = new ApiService(process.env.REACT_APP_BASEURL);

  const nextStep = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const getUserIDFromEmail = (email) => {
    return new Promise((resolve, reject) => {
      // Validate email
      if (!email) {
        reject(new Error("Email is required"));
        return;
      }

      // Make request to fetch user ID
      apiService
        .get(`api/user/id?email=${email}`)
        .then((response) => {
          // Check if response contains user ID
          if (response.data && response.data.userId) {
            // Resolve with the user ID from the response
            resolve(response.data.userId);
          } else {
            // If user ID is not found in the response, reject with an error
            reject(new Error("User ID not found"));
          }
        })
        .catch((error) => {
          // Handle network errors or errors returned from the API
          console.error("Error while fetching user ID from email:", error);
          reject(new Error("Failed to fetch user ID. Please try again later."));
        });
    });
  };
  const getUserIDByEmailFromAuth0 = async (email) => {
    try {
      // Make a request to the Auth0 Management API to get the user by email
      const response = await apiService.get(`api/user/auth0?email=${email}`);

      // Check if the response contains data
      if (response && response.data) {
        // Extract the user ID from the response data

        const { userId } = response.data;

        return userId;
      } else {
        // If the response is empty or missing data, throw an error
        throw new Error("Failed to fetch user ID. Response data is empty.");
      }
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error while fetching user ID from Auth0:", error);
      throw error; // Re-throw the error for further handling
    }
  };

  // Define items based on flowValue
  let items;
  if (flowValue === "Document Verification") {
    items = [
      {
        key: "Forgot Password",
        title: "Email Input",
        icon: <UserOutlined />,
      },
      {
        key: "Document Verification",
        title: "Document Verification",
        icon: <SolutionOutlined />,
      },
      {
        key: "Facial Recognition",
        title: "Facial Recognition",
        icon: <SmileOutlined />,
      },
      {
        key: "Create Password",
        title: "Create Password",
        icon: <PasswordIcon />,
      },
      {
        key: "Completed",
        title: "Completed",
        icon: <FileDoneOutlined />,
      },
    ];
  } else {
    if (flowValue === "KBA") {
      items = [
        {
          key: "Registration",
          title: "Registration",
          icon: <UserOutlined />,
        },
        {
          key: "LexisNexis InstantID Validation",
          title: "LexisNexis InstantID Validation",
          icon: <SolutionOutlined />,
        },
        {
          key: "Create Password",
          title: "Create Password",
          icon: <PasswordIcon />,
        },
        {
          key: "Completed",
          title: "Completed",
          icon: <FileDoneOutlined />,
        },
      ];
    }
  }

  return (
    <>
      <NavbarCom />
      <div className="p-10 py-24 bg-neutral-100">
        <div className="multi-step-parent-wrapper max-w-[650px] bg-white mx-auto  p-8 shadow-lg ">
          {current > 0 && (
            <button
              className="text-blue-950 hover:bg-neutral-200 mt-2 tex-sm  border-blue-950 text-sm font-medium rounded-full p-2  bg-neutral-100"
              style={{ margin: "0 8px" }}
              onClick={() => prev()}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            </button>
          )}
          {showStepper && (
            <Steps
              size="small"
              className="bg-white rounded-top pt-4 pb-2 border-bottom"
              current={current}
              items={items}
            />
          )}
          <div className="">
            {current === 0 && (
              <CustomProofing
                nextStep={nextStep}
                setCurrent={setCurrent}
                firstName={firstName}
                setFirstName={setFirstName}
                setLastName={setLastName}
                lastName={lastName}
                email={email}
                setEmail={setEmail}
                zipCode={zipCode}
                setZipCode={setZipCode}
                address1={address1}
                setAddress1={setAddress1}
                phone={phone}
                setPhone={setPhone}
                city={city}
                setCity={setCity}
                state={state}
                setState={setState}
                country={country}
                setCountry={setCountry}
                setshowStepper={setshowStepper}
                showOnfidoView={showOnfidoView}
                identityProofing={identityProofing}
                setIdentityProofing={setIdentityProofing}
                flowValue={flowValue}
                setFlowValue={setFlowValue}
                getUserIDFromEmail={getUserIDFromEmail}
                getUserIDByEmailFromAuth0={getUserIDByEmailFromAuth0}
              />
            )}
            {current === 1 && flowValue === "Document Verification" && (
              <SelectDocuments
                nextStep={nextStep}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                base64BackImageData={base64BackImageData}
                setBase64BackImageData={setBase64BackImageData}
                base64FrontImageData={base64FrontImageData}
                setBase64FrontImageData={setBase64FrontImageData}
                base64SelfieImageData={base64SelfieImageData}
                setBase64SelfieImageData={setBase64SelfieImageData}
              />
            )}
            {current === 1 && flowValue === "KBA" && (
              <ValidateWithLN
                nextStep={nextStep}
                setCurrent={setCurrent}
                firstName={firstName}
                lastName={lastName}
                email={email}
                zipCode={zipCode}
                address1={address1}
                phone={phone}
                city={city}
                state={state}
                country={country}
                forgotStepperValue={forgotStepperValue}
              />
            )}
            {current === 2 && flowValue === "Document Verification" && (
              <UploadFaceImages
                nextStep={nextStep}
                setCurrent={setCurrent}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                base64BackImageData={base64BackImageData}
                setBase64BackImageData={setBase64BackImageData}
                base64FrontImageData={base64FrontImageData}
                setBase64FrontImageData={setBase64FrontImageData}
                base64SelfieImageData={base64SelfieImageData}
                setBase64SelfieImageData={setBase64SelfieImageData}
              />
            )}
            {current === 3 && flowValue === "Document Verification" && (
              <CreatePassword
                email={email}
                nextStep={nextStep}
                loading={loading}
                setLoading={setLoading}
                getUserIDFromEmail={getUserIDFromEmail}
                getUserIDByEmailFromAuth0={getUserIDByEmailFromAuth0}
              />
            )}

            {current === 2 && flowValue === "KBA" && (
              <CreatePassword
                email={email}
                nextStep={nextStep}
                loading={loading}
                setLoading={setLoading}
                getUserIDFromEmail={getUserIDFromEmail}
                getUserIDByEmailFromAuth0={getUserIDByEmailFromAuth0}
              />
            )}
            {current === 4 && flowValue === "Document Verification" && (
              <Completed />
            )}
            {current === 3 && flowValue === "KBA" && <Completed />}
          </div>
          <div className="flex justify-between w-full"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotStepper;
