import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import InsightsIcon from "@mui/icons-material/Insights";
const Info = () => {
  return (
    <div>
      {" "}
      <section className="h-auto lg:mt-6 lg:pt-6 flex flex-col justify-center items-center gap-6 px-4 py-8">
        <div className="container flex flex-col justify-center items-center gap-16 sm:flex-row sm:items-start">
          <div className="flex flex-col justify-start items-start gap-6">
            <div className="text-zinc-800 text-3xl sm:text-4xl font-bold">
              BeyondID's Integration with{" "}
              <span className="text-sky-900">
                LexisNexis Identity Proofing Solution
              </span>
            </div>
            <div className="text-zinc-600 text-lg sm:text-2xl font-normal">
              LexisNexis Identity Proofing Solution, bringing enhanced identity
              verification capabilities to our customers through the power of
              Okta's identity platform.
            </div>
            <div className="px-8 py-4 bg-red-600 hover:bg-primary transition-colors duration-300 cursor-pointer rounded flex justify-center items-center gap-2.5">
              <div className="text-white text-base font-normal">
                Learn More{" "}
                <span>
                  {" "}
                  <ChevronRightOutlinedIcon />
                </span>
              </div>
            </div>
          </div>
          <img
            alt="id-proof-animate-svg"
            className="h-64 sm:h-96 mx-auto"
            src="./images/private-data-animate.svg"
          />
        </div>
      </section>
      <section
        id="benefits"
        className="w-full  px-4 py-8 lg:mt-6 lg:pt-6 bg-slate-100 flex flex-col justify-start items-center gap-12"
      >
        <div className="container">
          <div className="flex flex-col sm:flex-row justify-between items-end w-full">
            <div className="flex flex-col justify-start items-start gap-2 mb-6 sm:mb-0">
              <div className="text-zinc-800 text-3xl sm:text-5xl font-extrabold">
                Benefits of Our{" "}
                <span className="text-sky-900">Integration</span>
              </div>
              <div className="text-zinc-600 text-base font-normal">
                Our integration seamlessly combines the robust identity
                management features of Okta with the advanced identity proofing
                capabilities of LexisNexis. This enables organizations to verify
                the identities of their users with greater confidence,
                streamlining the onboarding process while maintaining stringent
                security standards.
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-between gap-4 w-full mt-5">
            <div className="p-6 max-w-sm bg-white rounded-lg hover:scale-105 transition-transform shadow border-l-4 border-blue-600 flex flex-col justify-center items-center gap-2.5">
              <div className="relative flex items-start w-full">
                <LockOutlinedIcon
                  fontSize="large"
                  className="text-neutral-900"
                />
              </div>
              <div className="flex flex-col justify-center items-start gap-4">
                <div className="text-zinc-800 text-xl sm:text-2xl font-semibold">
                  Enhanced Security
                </div>
                <div className="text-zinc-600 line-clamp-4 text-base font-normal">
                  By leveraging LexisNexis' comprehensive identity proofing
                  solution, organizations can significantly reduce the risk of
                  identity fraud and unauthorized access.
                </div>
                <div className="flex justify-center items-center">
                  <div className="text-zinc-800 text-sm font-normal">
                    Read More
                  </div>
                  <div className="relative">
                    <ChevronRightOutlinedIcon className="text-neutral-900" />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-6 h-72 max-w-96  bg-white rounded-lg shadow hover:scale-105 transition-transform duration-200 border-l-4 border-blue-600 flex-col justify-center items-center gap-2.5 inline-flex">
              <div className="self-stretch p-1 justify-start items-center gap-2.5 inline-flex">
                <div className="w-10 h-10 relative">
                  <RocketLaunchOutlinedIcon
                    fontSize="large"
                    className="w-8 h-8"
                  />
                </div>
              </div>
              <div className="flex-col justify-center items-start gap-4 flex">
                <div className="text-zinc-800 text-2xl font-semibold ">
                  Streamlined Onboarding
                </div>
                <div className=" line-clamp-4 text-zinc-600 text-base font-normal ">
                  Our integration simplifies the user onboarding process by
                  automating identity verification, allowing organizations to
                  onboard new users quickly and efficiently.
                </div>
                <div className="justify-center items-center inline-flex">
                  <div className="text-zinc-800 text-sm font-normal ">
                    Read More
                  </div>
                  <div className="w-6 h-6 relative">
                    <ChevronRightOutlinedIcon className="w-6 h-6" />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-6 h-72 max-w-96   bg-white rounded-lg shadow border-l-4 hover:scale-105 transition-transform duration-200 border-blue-600 flex-col justify-center items-center gap-2.5 inline-flex">
              <div className="self-stretch px-1 py-1 justify-start items-center gap-2.5 inline-flex">
                <div className="w-10 h-10 relative">
                  <AssuredWorkloadOutlinedIcon
                    fontSize="large"
                    className="text-neutral-900"
                  />
                </div>
              </div>
              <div className="flex-col justify-center items-start gap-4 flex">
                <div className="text-zinc-800 text-2xl font-semibold ">
                  Regulatory Compliance
                </div>
                <div className=" h-20 line-clamp-4 text-zinc-600 text-base font-normal ">
                  With increasing regulatory requirements around identity
                  verification, our integration ensures that organizations
                  remain compliant with relevant regulations, such as KYC (Know
                  Your Customer) and AML (Anti-Money Laundering).
                </div>
                <div className="justify-center items-center inline-flex">
                  <div className="text-zinc-800 text-sm font-normal ">
                    Read More
                  </div>
                  <div className="w-6 h-6 relative">
                    <ChevronRightOutlinedIcon className="w-4 h-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="solutions"
        className="w-full px-4 py-8 lg:mt-6 lg:pt-6 bg-white flex flex-col justify-start items-center gap-12"
      >
        <div className="container">
          <div className="flex flex-col sm:flex-row justify-start items-end gap-4 w-full">
            <div className="flex flex-col justify-start items-start gap-2">
              <div className="text-zinc-800 text-3xl sm:text-5xl font-extrabold">
                <span className="text-sky-900">Solutions</span> Offering
              </div>
              <div className="text-zinc-600 text-base font-normal">
                Our integrated solution offers a comprehensive approach to
                identity management, combining Okta's industry-leading identity
                platform with LexisNexis' advanced identity proofing
                capabilities. Whether you're a small business or a large
                enterprise, our solution can scale to meet your unique identity
                verification needs
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-between gap-4 mt-5 w-full">
            <div className="p-6 max-w-sm hover:shadow-md transition-all duration-300 bg-white rounded-lg shadow flex flex-col justify-center items-center gap-2.5">
              <div className="flex items-start w-full">
                <FingerprintOutlinedIcon
                  fontSize="large"
                  className="text-primary"
                />
              </div>
              <div className="flex flex-col justify-center items-start gap-4">
                <div className="text-zinc-800 text-xl sm:text-2xl font-semibold">
                  Identity Verification
                </div>
                <div className="line-clamp-4 text-zinc-600 text-base font-normal">
                  By leveraging LexisNexis' comprehensive identity proofing
                  solution, organizations can significantly reduce the risk of
                  identity fraud and unauthorized access.
                </div>
              </div>
            </div>
            <div className="p-6 h-72 max-w-96  hover:shadow-md transition-all duration-300 bg-white rounded-lg shadow flex-col justify-center items-center gap-2.5 inline-flex">
              <div className="flex items-start w-full">
                <AutoModeIcon fontSize="large" className="text-primary" />
              </div>
              <div className="flex-col justify-center items-start gap-4 flex">
                <div className="text-zinc-800 text-2xl font-semibold ">
                  Customizable Workflows
                </div>
                <div className=" line-clamp-4 text-zinc-600 text-base font-normal ">
                  Our integration simplifies the user onboarding process by
                  automating identity verification, allowing organizations to
                  onboard new users quickly and efficiently.
                </div>
              </div>
            </div>
            <div className="p-6 h-72 max-w-96  hover:shadow-md transition-all duration-300 bg-white rounded-lg shadow flex-col justify-center items-center gap-2.5 inline-flex">
              <div className="flex items-start w-full">
                <InsightsIcon fontSize="large" className="text-primary" />
              </div>
              <div className="flex-col justify-center items-start gap-4 flex">
                <div className="text-zinc-800 text-2xl font-semibold ">
                  Real Time Insights
                </div>
                <div className="line-clamp-4 text-zinc-600 text-base font-normal ">
                  With increasing regulatory requirements around identity
                  verification, our integration ensures that organizations
                  remain compliant with relevant regulations, such as KYC (Know
                  Your Customer) and AML (Anti-Money Laundering).
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="approach"
        className="w-full px-4 py-8 lg:mt-6 lg:pt-6 bg-slate-100 flex flex-col justify-start items-center gap-12"
      >
        <div className="container flex flex-col sm:flex-row gap-8 justify-center items-center">
          <div className="sm:order-last">
            <img
              className="h-auto max-w-full rounded-2xl"
              src="./images/approach.jpg"
              loading="lazy"
              alt="Our Approach"
            />
          </div>
          <div className="flex flex-col justify-start items-start gap-4">
            <div className="text-zinc-800 text-3xl sm:text-5xl font-extrabold">
              Our <span className="text-sky-900">Approach</span>
            </div>
            <div className="text-zinc-600 text-base sm:text-lg font-normal">
              At BeyondID, we take a customer-centric approach to every
              integration project. Our team works closely with your organization
              to understand your unique business requirements and objectives.
              From initial consultation to implementation and ongoing support,
              we are committed to delivering solutions that drive value and
              exceed expectations
            </div>
            <div className="bg-primary mt-6 sm:mt-10 cursor-pointer text-white hover:bg-secondary rounded px-8 py-4 flex items-center">
              <span>Get In touch</span>{" "}
              <span>
                {" "}
                <ChevronRightOutlinedIcon />
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Info;
