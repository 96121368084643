import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import ApiService from "../../services/ApiService";

const CustomProofingSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().required("Confirm Password is required"),
});

const CreatePassword = ({
  nextStep,
  email,
  loading,
  setLoading,
  getUserIDFromEmail,
  getUserIDByEmailFromAuth0,
}) => {
  const [selectedIdp, setSelectedIdp] = useState("");
  const apiService = new ApiService(process.env.REACT_APP_BASEURL);
  useEffect(() => {
    const idp = localStorage.getItem("selectedIdp");
    console.log("CreatePassword idp:", idp);
    if (idp) {
      setSelectedIdp(idp);
    }
  }, []);
  const updateUserPassword = async (userId, confirmPassword) => {
    try {
      const response = await apiService.put(`api/users/${userId}/password`, {
        confirmPassword,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to update user password");
      }
    } catch (error) {
      console.error("Error updating user password:", error);
      throw new Error(
        "Failed to update user password. Please try again later."
      );
    }
  };
  const updateUserPasswordInAuth0 = async (user_id, confirmPassword) => {
    try {
      const response = await apiService.patch(
        `api/user/auth0/${user_id}/password`,
        {
          password: confirmPassword,
        }
      );

      if (response.status === 200) {
        return response.data; // Return any data if needed
      } else {
        throw new Error("Failed to update user password");
      }
    } catch (error) {
      console.error("Error updating user password in Auth0:", error);
      throw new Error(
        "Failed to update user password in Auth0. Please try again later."
      );
    }
  };

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: CustomProofingSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let userId;
        if (selectedIdp === "Okta") {
          userId = await getUserIDFromEmail(email);
          await updateUserPassword(userId, values.password);
          setLoading(false);
          nextStep();
          setTimeout(() => {
            navigate("/login", { state: { selectedIdp } });
          }, 3000);
        } else if (selectedIdp === "Auth0") {
          let user_id;
          user_id = await getUserIDByEmailFromAuth0(email);
          await updateUserPasswordInAuth0(user_id, values.password);
          setLoading(false);
          nextStep();
          setTimeout(() => {
            navigate("/login", { state: { selectedIdp } });
          }, 3000);
        }
      } catch (error) {
        console.error("Error:", error.message);
        setLoading(false);
        // Handle error here (e.g., show error message to user)
      }
    },
  });

  return (
    <div className="flex flex-col gap-6 border shadow-sm dark:bg-dark bg-white max-w-[550px] box-content lg:mx-auto md:mx-4 sm:mx-2 my-10 rounded-lg p-8">
      <div className="py-2">
        <h4 className="text-blue-950 text-2xl font-medium mb-1">
          Create New Password
        </h4>
        <p className="text-neutral-500 text-sm mb-2">
          Please enter a new password.
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <input
          type="password"
          placeholder="Enter your password"
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="shadow-sm border p-2 w-full"
        />
        {formik.touched.password && formik.errors.password && (
          <div className="text-red-500 text-sm mt-1">
            {formik.errors.password}
          </div>
        )}
        <input
          type="password"
          placeholder="Confirm New Password"
          id="confirmPassword"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="shadow-sm border p-2 w-full"
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className="text-red-500 text-sm mt-1">
            {formik.errors.confirmPassword}
          </div>
        )}
        <div className="flex flex-col gap-4 pt-8">
          <Button
            variant="primary"
            type="submit"
            label={loading ? "Loading..." : "Update Password"}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default CreatePassword;
