import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavbarCom from "../NavbarCom";
import Footer from "../Footer";

const IdentityPlatform = () => {
  const [selectedIdp, setSelectedIdp] = useState("");
  const navigate = useNavigate();
  const handleSelection = (idp) => {
    localStorage.setItem("selectedIdp", idp);
    console.log("object");
    setSelectedIdp(idp);
    navigate("/register");
  };
  return (
    <>
      <NavbarCom />

      <div className="flex flex-col gap-6 border shadow-sm dark:bg-dark bg-white max-w-[550px] box-content lg:mx-auto md:mx-4 sm:mx-2  my-10 rounded-lg p-8">
        <div>
          <p className="text-2xl text-blue-900 font-semibold">Select IDP</p>
          <p className="text-neutral-600 text-sm ">
            Please select the IDP to proceed.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <button
            onClick={() => handleSelection("Auth0")}
            className=" bg-white text-gray-800 justify-center flex   py-4 px-4 rounded-lg border-2 focus:outline-none focus:ring- focus:ring-blue-300  hover:border-blue-800 hover:bg-blue-50 "
          >
            <img
              className="h-12 dark:filter:brightness-100"
              src="/auth0okta.png"
              alt="auth0"
            />
          </button>
          <button
            onClick={() => handleSelection("Okta")}
            className="bg-white text-gray-800 justify-center flex   py-4 px-4 rounded-lg border-2 focus:outline-none focus:ring- focus:ring-blue-300  hover:border-blue-800 hover:bg-blue-50"
          >
            <img className="h-8" src="/okta.png" alt="okta" />
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IdentityPlatform;
