import React, { Component, useEffect } from 'react';
import NavbarCom from './NavbarCom';
import Banner from './Banner';
import Footer from './Footer';
import Info from './Info';

import './Home.css';

class Home extends Component {
  constructor(props) {
    super(props);
    document.title = "LexisNexis | Home"
    this.state = {};
    this.state.searchParams = this.getQueryVariable('isUserCreated');
    // debugger;
  }
  // this.getQueryVariable.bind(this)
  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    console.log(query); //"app=article&act=news_content&aid=160990"
    var vars = query.split('&');
    console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  render() {
    return (
      <>
        <NavbarCom isUserCreated={this.getQueryVariable('isUserCreated')} />
        <Banner />
        <Info />
        <Footer />
      </>
    );
  }
}

export default Home;
