import React, { useState } from 'react';
import { Steps } from 'antd';
import {
  UserOutlined,
  SolutionOutlined,
  FileDoneOutlined,
  SmileOutlined,
} from '@ant-design/icons';

import Registration from '../Steps/Registration';
import SelectDocument from '../SelectDocument/SelectDocument';
import ValidateWithLN from '../kba/ValidateWithLN';
import UploadFaceImage from '../UploadFaceImage/UploadFaceImage';
import Submit from '../Submit/Submit';
import NavbarCom from '../NavbarCom';
import Footer from '../Footer';

const StepperForm = () => {
  const [current, setCurrent] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [address1, setAddress1] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [vRequired, setValueRequired] = useState(true);
  const [sdkToken, setSDKToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [verifyState, setVerifyState] = useState({
    isComplete: undefined,
    checkId: '',
  });
  const [showStepper, setshowStepper] = useState(false);
  const [base64FrontImageData, setBase64FrontImageData] = useState(null);
  const [base64BackImageData, setBase64BackImageData] = useState(null);
  const [base64SelfieImageData, setBase64SelfieImageData] = useState(null);

  const [showOnfidoView, setShowOnfidoView] = useState(false);
  const [identityProofing, setIdentityProofing] = useState('');
  const [flowValue, setFlowValue] = useState('');
  const forgotStepperValue = false;

  const nextStep = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  // Define items based on flowValue
  let items;
  if (flowValue === 'Document Verification') {
    items = [
      {
        key: 'Registration',
        title: 'Registration',
        icon: <UserOutlined />,
      },
      {
        key: 'Document Verification',
        title: 'Document Verification',
        icon: <SolutionOutlined />,
      },
      {
        key: 'Facial Recognition',
        title: 'Facial Recognition',
        icon: <SmileOutlined />,
      },
      {
        key: 'Completed',
        title: 'Completed',
        icon: <FileDoneOutlined />,
      },
    ];
  } else {
    if (flowValue === 'KBA') {
      items = [
        {
          key: 'Registration',
          title: 'Registration',
          icon: <UserOutlined />,
        },
        {
          key: 'LexisNexis InstantID Validation',
          title: 'LexisNexis InstantID Validation',
          icon: <SolutionOutlined />,
        },
        {
          key: 'Completed',
          title: 'Completed',
          icon: <FileDoneOutlined />,
        },
      ];
    }
  }
  // debugger;
  return (
    <>
      <NavbarCom />
      <div className='p-10 py-24 bg-neutral-100'>
        <div className='multi-step-parent-wrapper max-w-[650px] bg-white mx-auto  p-8 shadow-lg '>
          {current > 0 && (
            <button
              className='text-blue-950 hover:bg-neutral-200 mt-2 tex-sm  border-blue-950 text-sm font-medium rounded-full p-2  bg-neutral-100'
              style={{ margin: '0 8px' }}
              onClick={() => prev()}
              type='button'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'
                />
              </svg>
            </button>
          )}
          {showStepper && (
            <Steps
              size='small'
              className='bg-white rounded-top pt-4 pb-2 border-bottom'
              current={current}
              items={items}
            />
          )}
          <div className=''>
            {current === 0 && (
              <Registration
                nextStep={nextStep}
                setCurrent={setCurrent}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                zipCode={zipCode}
                setZipCode={setZipCode}
                address1={address1}
                setAddress1={setAddress1}
                phone={phone}
                setPhone={setPhone}
                city={city}
                setCity={setCity}
                state={state}
                setState={setState}
                country={country}
                setCountry={setCountry}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                setshowStepper={setshowStepper}
                showOnfidoView={showOnfidoView}
                setShowOnfidoView={setShowOnfidoView}
                identityProofing={identityProofing}
                setIdentityProofing={setIdentityProofing}
                flowValue={flowValue}
                setFlowValue={setFlowValue}
              />
            )}
            {current === 1 && flowValue === 'Document Verification' && (
              <SelectDocument
                nextStep={nextStep}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                base64BackImageData={base64BackImageData}
                setBase64BackImageData={setBase64BackImageData}
                base64FrontImageData={base64FrontImageData}
                setBase64FrontImageData={setBase64FrontImageData}
                base64SelfieImageData={base64SelfieImageData}
                setBase64SelfieImageData={setBase64SelfieImageData}
              />
            )}
            {current === 1 && flowValue === 'KBA' && (
              <ValidateWithLN
                nextStep={nextStep}
                prev={prev}
                firstName={firstName}
                lastName={lastName}
                email={email}
                password={password}
                zipCode={zipCode}
                address1={address1}
                phone={phone}
                city={city}
                state={state}
                country={country}
                forgotStepperValue={forgotStepperValue}
              />
            )}
            {current === 2 && flowValue === 'Document Verification' && (
              <UploadFaceImage
                nextStep={nextStep}
                setCurrent={setCurrent}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                base64BackImageData={base64BackImageData}
                setBase64BackImageData={setBase64BackImageData}
                base64FrontImageData={base64FrontImageData}
                setBase64FrontImageData={setBase64FrontImageData}
                base64SelfieImageData={base64SelfieImageData}
                setBase64SelfieImageData={setBase64SelfieImageData}
              />
            )}
            {current === 3 && flowValue === 'Document Verification' && (
              <Submit />
            )}
            {current === 2 && flowValue === 'KBA' && <Submit />}
          </div>
          <div className='flex justify-between w-full'>
            {/* {current < items.length - 1 && (
                          <Button type="default" onClick={nextStep}>
                              Next
                          </Button>
                      )} */}
            {/* {current === items.length - 1 && (
              <Button
                type="default"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </Button>
            )} */}
            {/* {current > 0 && (
              <button
                className="btn-dark btn ml-0 rounded"
                style={{ margin: "0 8px" }}
                onClick={prev}
              >
                Previous
              </button>
            )} */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StepperForm;
