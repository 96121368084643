import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Auth = () => {
  const location = useLocation();
  const selectedIdp = location.state && location.state.selectedIdp;
  const idp = localStorage.getItem("selectedIdp");

  document.title = "LexisNexis | login";

  const { oktaAuth, authState } = useOktaAuth();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const signIn = async () => {
      if (!authState) {
        return;
      }

      if (idp === "Okta" && !authState.isAuthenticated) {
        try {
          await oktaAuth.signInWithRedirect();
        } catch (error) {
          console.error("Error signing in with Okta:", error);
        }
      } else if (idp === "Auth0") {
        loginWithRedirect();
      }
    };

    signIn();
  }, [idp, authState, oktaAuth, loginWithRedirect]);

  return null;
};

export default Auth;
