import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../components/Register/Register.css';
import 'onfido-sdk-ui/dist/style.css';
import ApiService from '../../services/ApiService';
import useInterval from '../util/interval';

import { useFormik } from 'formik';
import { Input } from 'antd';
import * as Yup from 'yup';
import { FormCheck, FormGroup } from 'react-bootstrap';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import Button from '../button';

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(4, 'Password must be at least 4 characters')
    .required('Password is required'),
});

const Registration = ({
  nextStep,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  username,
  setUsername,
  email,
  setEmail,
  password,
  setPassword,
  zipCode,
  setZipCode,
  address1,
  setAddress1,
  phone,
  setPhone,
  city,
  setCity,
  state,
  setState,
  country,
  setCountry,
  setValueRequired,
  setIsUserCreated,
  verifyState,
  setVerifyState,
  setshowStepper,
  showOnfidoView,
  identityProofing,
  setIdentityProofing,
  flowValue,
  setFlowValue,
}) => {
  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: '',
      zipCode: zipCode || '',
      address1: address1 || '',
      phone: phone || '',
      city: city || '',
      state: state || '',
      country: country || '',
      acceptTerms: false,
    },
    validationSchema: RegisterSchema.concat(
      Yup.object().shape({
        ...(flowValue === 'KBA'
          ? {
              zipCode: Yup.string().required('Zip Code is required'),
              address1: Yup.string().required('Address is required'),
              phone: Yup.string().required('Phone is required'),
              city: Yup.string().required('City is required'),
              state: Yup.string().required('State is required'),
              country: Yup.string().required('Country is required'),
            }
          : {}),
        acceptTerms: Yup.bool()
          .oneOf([true], 'Please accept the terms and conditions')
          .required('Please accept the terms and conditions'),
      })
    ),
    onSubmit: async (values) => {
      console.log('onSubmit', values);
      setFirstName(values.firstName);
      setLastName(values.lastName);
      setEmail(values.email);
      setPassword(values.password);
      if (flowValue === 'KBA') {
        setZipCode(values.zipCode);
        setAddress1(values.address1);
        setPhone(values.phone);
        setCity(values.city);
        setState(values.state);
        setCountry(values.country);
      }
      nextStep(); //current++
    },
  });

  useEffect(() => {
    // Check if firstName or lastName has changed
    if (
      formik.values.firstName !== firstName ||
      formik.values.lastName !== lastName
    ) {
      const { firstName, lastName } = formik.values;

      if (firstName !== '' && lastName !== '') {
        const email = `${firstName}.${lastName}@mailinator.com`;
        const username = `${firstName}.${lastName}`;

        // Update formik state
        formik.setFieldValue('email', email);
        formik.setFieldValue('username', username);

        // Also update the external state (if needed)
        setUsername(username);

        setValueRequired(false);
      } else {
        // Clear values if either firstName or lastName is empty
        formik.setFieldValue('email', '');
        formik.setFieldValue('username', '');
        setUsername(''); // Clear the external state as well
        setValueRequired(true);
      }
    }
  }, [firstName, lastName, formik.values.firstName, formik.values.lastName]);

  document.title = 'LexisNexis | Register';
  const apiService = new ApiService(process.env.REACT_APP_BASEURL);

  useInterval(
    () => {
      if (!verifyState.isComplete && verifyState.isComplete !== undefined) {
        checkStatus(verifyState.checkId);
      }
    },
    !verifyState.isComplete ? 10000 : null
  );

  const checkStatus = (data) => {
    apiService
      .post('api/status', data)
      .then((resp) => {
        console.log('respo -> ', resp);
        if (resp.data.checkStatus === 'complete') {
          setVerifyState((prevState) => ({
            ...prevState,
            isComplete: true,
            result: resp.data.checkResult,
          }));
        }
      })
      .catch((err) => console.log('130 err -<> ', err));
  };

  const enterGoodData = () => {
    console.log('Enter good data');
    populateInputs(
      'John',
      'Pprivet',
      '647 NORRISTOWN RD',
      'HAMPTON',
      'VA',
      '23666',
      'USA',
      '5137111111'
    );
    // Clear any existing form errors
    // formik.resetForm({ values: formik.initialValues });
  };

  const enterBadData = () => {
    console.log('Enter bad data');
    populateInputs(
      'Bob',
      'Pprivet',
      '1200 Mapel Street',
      'HAMPTON',
      'VA',
      '90210',
      'USA',
      '5137111111'
    );
    // Clear any existing form errors
    // formik.resetForm({ values: formik.initialValues });
  };

  function populateInputs(
    fName,
    lName,
    address,
    city,
    state,
    zip,
    country,
    phone
  ) {
    // Set Formik field values
    formik.setFieldValue('firstName', fName);
    formik.setFieldValue('lastName', lName);
    // Set other Formik field values as needed

    // Generate email and username
    const generatedEmail = `${fName}.${lName}@mailinator.com`;
    const generatedUsername = `${fName}.${lName}`;

    // Set Formik field values for email and username
    formik.setFieldValue('email', generatedEmail);
    formik.setFieldValue('username', generatedUsername);
    formik.setFieldValue('zipCode', zip);
    formik.setFieldValue('address1', address);
    formik.setFieldValue('phone', phone);
    formik.setFieldValue('city', city);
    formik.setFieldValue('state', state);
    formik.setFieldValue('country', country);
  }
  // const onToggleSwitch = () => {
  //   setShowOnfidoView(!showOnfidoView);
  //   setshowStepper(!showOnfidoView);
  // };

  return (
    <div className='registration-form rounded-bottom'>
      <div className='d-flex justify-content-end'></div>
      <div className='d-flex justify-content-end'>
        {/* {!showOnfidoView && (
                  <button
                    onClick={onsubmit}
                    className="btn btn-primary w-25 btn-block rounded"
                  >
                    Submit
                  </button>
                )} */}
        {/* {showOnfidoView && (
          <button
            onClick={() => {
              setNext(true);
            }}
            className="btn btn-primary w-25 btn-block rounded"
            id="submit"
          >
            Next
          </button>
        )} */}
      </div>
      <div className=' flex flex-col'>
        <div className='py-2'>
          <h4 className='text-blue-950 text-2xl font-medium mb-1'>
            Identity Proofing Method
          </h4>
          <p className='text-neutral-500 text-sm mb-2'>
            Please select the identity proofing method to proceed.
          </p>
        </div>
        <div className='flex flex-col gap-3 mb-4'>
          {
            <button
              id='submit'
              type='button'
              className={`w-full border-2 items-center py-4 px-4 justify-start flex rounded-lg focus:outline-none focus:ring focus:ring-blue-300 hover:border-blue-800 hover:text-blue-800 hover:bg-blue-50 font-medium ${
                identityProofing === 'Proceed With  Document Verification'
                  ? 'text-blue-900 border-blue-900 bg-blue-50'
                  : ''
              } ${identityProofing}`}
              onClick={() => {
                setIdentityProofing('Proceed With  Document Verification');
                setshowStepper(!showOnfidoView);
                setFlowValue('Document Verification');
                formik.resetForm();
              }}
            >
              Document Verification{' '}
              {identityProofing === 'Proceed With  Document Verification' && (
                <span className='ml-auto text-blue-900'>
                  <svg
                    width='18'
                    height='14'
                    viewBox='0 0 18 14'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1.5 8L5.7257 12.2257C6.1415 12.6415 6.82457 12.6105 7.20102 12.1588L16.5 1'
                      stroke='#1e3a8a'
                      stroke-width='2'
                      stroke-linecap='round'
                    />
                  </svg>
                </span>
              )}
            </button>
          }
          <button
            type='button'
            className={`w-full  border-2 font-medium items-center py-4 px-4 justify-start flex rounded-lg focus:outline-none focus:ring focus:ring-blue-300 hover:border-blue-800 hover:text-blue-800 hover:bg-blue-50 ${
              identityProofing === 'Proceed With KBA'
                ? 'text-blue-900 border-blue-900 bg-blue-50'
                : ''
            }`}
            onClick={() => {
              setIdentityProofing('Proceed With KBA');
              setshowStepper(!showOnfidoView);
              setFlowValue('KBA');
              formik.resetForm();
            }}
          >
            KBA
            {identityProofing === 'Proceed With KBA' && (
              <span className='ml-auto text-blue-900'>
                <svg
                  width='18'
                  height='14'
                  viewBox='0 0 18 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.5 8L5.7257 12.2257C6.1415 12.6415 6.82457 12.6105 7.20102 12.1588L16.5 1'
                    stroke='#1e3a8a'
                    stroke-width='2'
                    stroke-linecap='round'
                  />
                </svg>
              </span>
            )}
          </button>
        </div>
      </div>
      <h4 className='text-blue-950 text-2xl font-medium mt-4 mb-1'>
        Enrollment Form
      </h4>
      <p className='text-neutral-500 text-sm mb-2'>
        Please fill out the following fields to proceed to registration
      </p>
      {flowValue === 'KBA' && (
        <div className='flex gap-4 mb-4 mt-4'>
          <button
            className='py-3 px-4 border-2 border-gray-200 hover:bg-gray-200 text-gray-700 text-md  w-full rounded'
            type='button'
            id='goodData'
            onClick={enterGoodData}
          >
            Fill <b>Accurate</b> Information
          </button>
          <button
            className='py-3 px-4 border-2 border-gray-200 hover:bg-gray-200 text-gray-700 text-md  w-full rounded'
            type='button'
            id='badData'
            onClick={enterBadData}
          >
            Fill <b>Inaccurate</b> Information
          </button>
        </div>
      )}
      <section className='section-register'>
        <div className='login-container'>
          <div className='login-panel gap-1' style={{ zIndex: 100 }} id='login'>
            <form onSubmit={formik.handleSubmit}>
              <div className=''>
                <div className='py-2'>
                  <input
                    type='text'
                    className='shadow-sm border p-2  w-full'
                    placeholder='First Name*'
                    id='fname'
                    name='firstName'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className='text-red-500 text-sm mt-1'>
                      {formik.errors.firstName}
                    </div>
                  )}
                </div>

                <div className='py-2'>
                  <input
                    type='text'
                    className='shadow-sm border p-2  w-full'
                    placeholder='Last Name*'
                    id='lname'
                    name='lastName'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='text-red-500 text-sm mt-1'>
                      {formik.errors.lastName}
                    </div>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='form-group col-sm-6 py-2'>
                  <input
                    type='text'
                    className='shadow-sm border p-2  w-full'
                    placeholder='Username'
                    id='username'
                    name='username'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div className='text-red-500 text-sm mt-1'>
                      {formik.errors.username}
                    </div>
                  )}
                </div>
                <div className='form-group col-sm-6 py-2'>
                  <input
                    type='text'
                    className='shadow-sm border p-2  w-full'
                    placeholder='Email*'
                    id='email'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='text-red-500 text-sm mt-1'>
                      {formik.errors.email}
                    </div>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='form-group col-sm-6 py-2'>
                  <input
                    type='password'
                    className='shadow-sm border p-2  w-full'
                    placeholder='Password*'
                    id='password'
                    name='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='text-red-500 text-sm mt-1'>
                      {formik.errors.password}
                    </div>
                  )}
                </div>

                {flowValue === 'KBA' && (
                  <>
                    <div className='form-group col-sm-6 py-2'>
                      <input
                        type='text'
                        className='shadow-sm border p-2  w-full'
                        placeholder='ZipCode*'
                        id='zcode'
                        name='zipCode'
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.zipCode && formik.errors.zipCode && (
                        <div className='text-red-500'>
                          {formik.errors.zipCode}
                        </div>
                      )}
                    </div>

                    <div className='form-group col-sm-6 py-2'>
                      <input
                        type='text'
                        className='shadow-sm border p-2  w-full'
                        placeholder='Address 1*'
                        id='addr1'
                        name='address1'
                        value={formik.values.address1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.address1 && formik.errors.address1 && (
                        <div className='text-red-500'>
                          {formik.errors.address1}
                        </div>
                      )}
                    </div>

                    <div className='form-group col-sm-6 py-2'>
                      <input
                        type='text'
                        className='shadow-sm border p-2  w-full'
                        placeholder='Phone*'
                        id='phn'
                        name='phone'
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <div className='text-red-500'>
                          {formik.errors.phone}
                        </div>
                      )}
                    </div>

                    <div className='form-group col-sm-6 py-2'>
                      <input
                        type='text'
                        className='shadow-sm border p-2  w-full'
                        placeholder='City*'
                        id='ct'
                        name='city'
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.city && formik.errors.city && (
                        <div className='text-red-500'>{formik.errors.city}</div>
                      )}
                    </div>

                    <div className='form-group col-sm-6 py-2'>
                      <input
                        type='text'
                        className='shadow-sm border p-2  w-full'
                        placeholder='State*'
                        id='st'
                        name='state'
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.state && formik.errors.state && (
                        <div className='text-red-500'>
                          {formik.errors.state}
                        </div>
                      )}
                    </div>

                    <div className='form-group col-sm-6 py-2'>
                      <input
                        type='text'
                        className='shadow-sm border p-2  w-full'
                        placeholder='Country*'
                        id='ctry'
                        name='country'
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.country && formik.errors.country && (
                        <div className='text-red-500'>
                          {formik.errors.country}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* <div className="form-group col-sm-6 "
                  <input
                    type="password"
                    className="shadow-sm border p-2  w-full "
                    placeholder="Confirm Password*"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.confirmPassword}
                      </div>
                    )}
                </div> */}
                <FormGroup>
                  <FormCheck className='flex gap-2 items-center mt-4'>
                    <Input
                      className='custom-consent-check w-4 h-4'
                      type='checkbox'
                      id='acceptTermsCheckbox ' // Added an id for the label association
                      name='acceptTerms'
                      checked={formik.values.acceptTerms}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormCheckLabel
                      className='custom-consent-label text-left'
                      htmlFor='acceptTermsCheckbox'
                    >
                      <p className='consent-class text-left'>
                        I accept the terms and conditions.
                      </p>
                    </FormCheckLabel>
                  </FormCheck>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='text-red-500 text-sm mt-1'>
                      {formik.errors.acceptTerms}
                    </div>
                  )}
                </FormGroup>

                <div className='flex flex-col gap-4 pt-8'>
                  {!!identityProofing && (
                    <Button
                      variant='primary'
                      type='button'
                      label={identityProofing}
                    />
                  )}
                  {/* {!showOnfidoView && (
                    <button
                      type="submit"
                      onClick={() => {
                        setNext(false);
                        onsubmit();
                        // setIdentityProofing(false);
                        // onsubmit();
                      }}
                      className="transition-colors duration-200 text-sm  text-neutral-500 rounded flex py-2 px-2 md:py-3 border-2 focus:outline-none hover:bg-blue-900 hover:border-blue-900 focus:ring focus:ring-blue-800 hover:text-white justify-center text-md w-full"
                    >
                      Skip Identify Proofing & Register
                    </button>
                  )} */}
                </div>
              </div>
              <span className='text-red-500 text-sm mt-1'></span>
              <input name='__RequestVerificationToken' type='hidden' />
            </form>
          </div>
          {showOnfidoView && <div id='onfido-mount'></div>}
        </div>
      </section>
    </div>
  );
};

export default Registration;
