import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
// import "./Home.css";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./custom.css";
import { Auth0Provider } from "@auth0/auth0-react";
const root = ReactDOM.createRoot(document.getElementById("root"));
const providerConfig = {
  domain: "auth-lexisnexis.beyondid-demo.com",
  clientId: "JKEjYRtt6fWmz9eUdIMX9WHzwXaIN8yc",
  // ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  // onRedirectCallback,
  cacheLocation: "localstorage",
  scope: "openid profile email",
};
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider {...providerConfig}>
        <App />
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);
