

const oktaSignInConfig = {
  clientId: '0oah0x4cfrx8K5g3P4x7',
  redirectUri: window.location.origin + '/login/callback',
  issuer: 'https://lexisnexis-auth.beyondid-demo.com/oauth2/default',
  scopes: ['openid', 'profile', 'email'],
  useInteractionCodeFlow: false,
  pkce: true,
  postLogoutRedirectUri: 'http://localhost:3000',
  //   postLogoutRedirectUri: 'https://node-react-onfido.herokuapp.com',
  baseUrl: 'https://lexisnexis-auth.beyondid-demo.com/',
};
export default oktaSignInConfig;
